.blog-search {
    display: flex;
    margin: 120px;
}

.blog-search>.images {
    display: flex;
    align-items: center;
    gap: 48px;
    margin-right: 20%;
}

.blog-search>.getnet-logo {
    margin-top: auto;
    margin-right: 40px; 
    width: 90px; 
    height: auto;
    transform: scale(2.5);
}


.blog-search-text {
    margin-top: 10%;
    margin-left: 15px;
}

.blog-search-text>h2 {
    font-size: 60px;
    width: 80%;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.input-container input {
    width: 60%;
    padding: 10px 10px 10px 35px;
    border: none;
    border-radius: 10px;
    background-color: #EEEEEE;
    font-size: 16px;
    color: #EC0000;
}

.input-container input::placeholder {
    color: #EC0000;
}

.search-icon {
    position: absolute;
    left: 10px;
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.blog-search-text>input::placeholder {
    color: #EC0000;
}

@media (max-width: 768px) {
    .blog-page>.blog-search {
        flex-direction: column;
        margin: 50px;
    }

    .blog-page>.blog-search>.images {
        flex-direction: column;
        max-width: 100%;
    }

    .blog-search>.getnet-logo {
        margin-top: auto;
        margin-left: auto; 
        transform: scale(0.5);
    }

    .blog-page>.blog-articles {
        padding: 16px;
        margin: 16px;

    }

    .blog-page>.blog-articles>.blog-article {
        grid-template-columns: 100%;
    }

    .article>a {
        display: flex;
        flex-direction: column;
    }

    .article.primary {
        grid-row-start: 2 !important;
        grid-column-start: 1 !important;
    }

    .article.primary>a>img {
        height: 200px !important;
    }

    .article-text {
        margin-left: 0px;
    }

    .blog-testimonials {
        margin: 16px !important;
    }

    .testimonial {
        flex-direction: column !important;
        max-width: 90% !important;
    }

    .blog-search-text>h2 {
        font-size: 40px;
        width: 100%;
    }

}