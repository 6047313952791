.details {
    display: flex;
    flex-direction: column;
  }
  .detail-product {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    margin: 5%;
  }
  .detail-image {
    background-color: #DEEDF2;
    text-align: center;
    padding: 96px;
    width: 90%;
  }
  .detail-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .detail-card > p {
    text-align: center;
  }
  .detail-image > img {
    height: 500px;
  }
  .getcheckout-page > .details > .detail-product > .detail-image > img {
    height: 200px;
  }
  .centrodepagos-page > .details > .detail-product > .detail-image >img {
    height: 200px;
  }
  .paybylink-page > .details > .detail-product > .detail-image {
    padding: 24px;
    background-color: transparent;
  }
  .getmini-page > .details > .detail-product > .detail-image > img {
    width: 100%;
    height: auto;
  } 

  @media only screen and (max-width: 768px) {
    .detail-product {
      display: flex;
      flex-direction: column;
    }
    .detail-image > img {
      height: 300px;
    }
    .details {
      margin-top: 24px;
    }
    .detail-image {
      width: 350px;
      padding: 16px;
    }
    .getcheckout-page > .details > .detail-product > .detail-image  {
      padding: 16px;
    }
    .getcheckout-page > .details > .detail-product > .detail-image > img {
      height: 184px;
    }
    .centrodepagos-page > .details > .detail-product > .detail-image > img {
      height: 184px;
    }
    .centrodepagos-page> .details > .detail-product > .detail-image  {
      padding: 16px;
    }

  }