.banner-content {
  margin: 8% 48% 4% 8%;
  color: white;
  padding: 14% 2%;
}

@media only screen and (max-width: 768px) {
  .banner-content {
    margin: 2px;
    padding: 25px;
  }
}