.carousel-products {
    margin: 96px;
}
.carousel-products > h2 {
    text-align: center;
}
.carousel-products > p {
    text-align: center;
}
@media only screen and (max-width: 768px) {
    .carousel-products {
        margin: 20px;
    }
}