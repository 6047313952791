.functionalities {
  margin: 96px;
} 
.functionalities > h2 {
  margin-bottom: 48px;
  text-align: center;
}
.functionalities > p {
  font-size: 20px;
  text-align: center;
  margin-bottom: 48px;
}
.card.functionality-card {
  height: 616px;
  display: flex;
  border: none;
}
.funcionality-image {
  text-align: center;
  background-color: #DCEBF0;
  width: 100%;
  margin: 0;
}
.functionality-card > .card-body > .card-title {
  color: black;
}
.card-text {
  color: #767676;
  font-size: 20px;
}
.card-body > p {
  color: #767676;
  font-size: 20px;
}
.card-gtapp > .carousel-inner > .carousel-item > .row > .col-4 > .functionality-card {
  height: 490px;
}
.getmini-page > .functionalities {
  display: none;
}
@media only screen and (max-width: 768px) {
  .functionalities {
    margin: 16px 16px 96px 16px
  }
  .functionality-card > .funcionality-image > img {
    height: 300px;
    width: max-content !important;
    margin: auto;
  }
  .card-gtapp  > .carousel-inner > .carousel-item > .row > .col-4 > .card.functionality-card {
    height: 480 px;
  }
}