@font-face {
  font-family: 'Santander';
  src: url('../public/media/fonts/SantanderHeadline-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Santander-Regular';
  src: url('../public/media/fonts/SantanderHeadline-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: 'Santander-Regular' !important;
  margin: 48px;
}
/* Navbar base styles */
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  max-width: 95% !important;
}
.nav-getnet .nav-link {
  color: #333;
  font-weight: 500;
  margin-right: 15px;
  position: relative;
  cursor: pointer;
  font-size: 16px;
}
.dropdown-toggle::after {
  color: red;
  font-weight: bolder;
}

.dropdown-menu > a {
  font-size: 20px;
}
a.item-menu {
  text-decoration: none;
}
.nav-getnet .nav-link:hover {
  color: #d21f1f;
  text-decoration: underline 4px;
  text-underline-offset: 14px;
}
/* Dropdown styles */
.nav-getnet .dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0;
  z-index: 1000;
  min-width: fit-content;
}
.nav-getnet .dropdown:hover .dropdown-menu {
  display: flex;
  width: 100%;
}
.nav-getnet .dropdown-menu .dropdown-item {
  color: #333;
  padding: 10px 20px;
  white-space: nowrap;
}
.nav-getnet .dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
  color: #d21f1f;
}
/* Button styles */
.btn-contact-header {
  font-weight: bold;
  border: 1px solid #d21f1f;
  color: #d21f1f;
  border-radius: 20px;
  padding: 5px 15px;
}
.btn-contact-header:hover {
  background-color: #d21f1f;
  color: #fff;
}
.btn-contact-header{
  background-image: url('../public/media/images/header/communication_mail.png');
  background-repeat: no-repeat;
  background-position-y: 50%;
  font-weight: bold !important;
  background-position-x: 10%;
  background-color: white;
  border: #EC0000 solid 1px;
  border-radius: 8px !important;
  padding: 4px 24px 4px 40px !important;
  color: #EC0000;
  height: 32px;
}
/*Footer*/
.footer {
  background-color: #1A1A1A;
  color: white;
  margin-top: 96px;
}
.footer > h1 {
  margin: 24px auto;
  padding: 24px;
  text-align: center;
}
.footer-top {
  border-bottom: 2px #545555 solid ;
}
.footer-top-content {
  margin: 0 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-top-image.footer-img {
  cursor: pointer;
}
.footer > div.flex-column.nav.footer-nav > button {
  color: white !important; 
}
p.footer-top-text {
  margin-left: 55%;
}
.social {
  display: flex;
  gap: 14px;
  margin-right: 20px;
  justify-content: center;
}
.footer-botom {
  display: flex;
  margin: 5% 5% auto;
  justify-content: space-around;
  padding-bottom: 20px;
  flex-wrap: wrap;
}
.footer-botom > div {
  width: 23%;
}
.tt {
  height: 38px;
  width: 35px;
  border-radius: 4px; 
}
.about-us > a {
  color: white;
  text-decoration: none;
  gap: -z
}
.about-us > p{
  color: white;
  text-decoration: none !important;
}
.isos > a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}
@media only screen and (max-width: 768px) {
  .footer-top {
    flex-direction: column;
  }
  .footer-botom {
    flex-direction: column;
    align-items: center;
  }
  p.footer-top-text { 
    margin-left: 0;
  }
  .footer-botom > div {
    width: 90%;
  }
  .about-us {
    text-align: center;
    margin: 24px 0;
    gap: 0;
  }
  .isos {
    text-align: center;
  }
  .client-service {
    text-align: center;
  }
  .payment-methods {
    text-align: center;
    margin: 24px 0;
  }
}
/*End Footer*/
/*Buttons*/
.action-primary {
  background-color: #EC0000 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 800;
  border-radius: 60px !important;
  font-size: 16px !important;
  padding: 12px 48px 12px 48px !important;
  /* text-shadow:1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000; */
}
.action-primary:hover {
  background-color: white !important;
  color: #EC0000 !important; 
}
.action-secondary {
  background-color: white;
  color: #EC0000;
  border: 1px solid #EC0000;  
  padding: 5px 28px 5px 28px;
  border-radius: 60px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bolder;
}
.action-secondary:hover {
  background: #EC0000;
  color: white;
}
.action-secondary::after {
  content: '>';
  font-size: 18px;
  margin-left: 10px;
  color: red;
  transition: transform 0.3s ease;
}

#launcher{
  border-radius: 25px!important;
  bottom:240px!important;
}
.custom-chat-button {
  position: fixed;
  bottom: 250px; 
  right: 70px; 
  background: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-radius:5px;
  opacity: 0;
  transform: translateY(20px);
  animation: show-hide 6s ease-in-out forwards;
  animation-delay: 3s;
  z-index: 9999;
}

@keyframes show-hide {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  20% {
    opacity: 1;
    transform: translateX(0);
  }
  80% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

@media (max-width: 479px) {
  #launcher {
    transform: scale(0.7) !important;
    right:5px!important;
    }
  .custom-chat-button{
    display:none;
  }
}

/* a::after {
  content: '';
  background-image: url(../public/media/images/home/arrow.png);
  left: 150px;
  width: 20px;
  height: 20px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat; 

} */
/*End Buttons*/

