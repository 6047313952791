.contact-form {
    margin: 8%;
}
.contact-form > h1 {
    text-align: center;
}
.contact-form > p {
    text-align: center;
}
.contact-form > .source-text {
    display: none;
}
form.contact-form.isos {
    margin: 0 8%;
}
.isos-btn {
    width: 30%;
}
@media only screen and (max-width: 768px) {
    .contact-form > .mb-3.row {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
    .isos-btn {
        width: 80%;
    }
}