.welcome-home {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../../public/media/images/home/videoweblow1.png);
  background-size: cover;
  background-position: center;
  height: 600px;
  justify-content: center;
  }
  .welcome-home > h1 {
    color: white;
    font-family: 'Santander-Regular';
    margin: 8% 48% 4% 8%;
  }
  .welcome-home > h1 > span {
    color: white;
    font-family: 'Santander';
  }
  .welcome-home > button {
    margin-left: 8%;
  }
  .welcome-home > p {
    color: white;
    font-size: 20px;
    margin: 0% 50% 4% 8%;
  }

@media only screen and (max-width: 1023px) {
  .welcome-home {
    margin-top:100px;
  }
}

@media only screen and (max-width: 768px) {
  .welcome-home {
    margin-top:100px;
    height: 100%; 
    width: 100%;  
    background: none;
    justify-content: flex-start;
    padding: 0 4%
  }

  .welcome-home > h1 {
    color: black;
    font-family: 'Santander-Regular';
    font-size: 54px; 
    margin: 10px 24px; 
    text-align: start; 
  }

  .welcome-home > h1 > span {
    color: black;
    font-family: 'Santander';
  }

  .welcome-home > button {
    margin-top: 30px;
  }

  .welcome-home > p {
    color: black;
    font-size: 16px;
    margin: 0 4%;
    text-align: center; 
  }
}