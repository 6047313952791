/* Estilo para el fondo oscuro del modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; 
  }

  .modal-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    animation: fadeIn 0.3s ease-out;
    max-height: 90vh; 
    overflow-y: auto;
  }
  .modal-content {
    padding: 48px;
    color: #444444;
  }
  .modal-btn {
    width: 50%;
    text-transform: uppercase;
  }
  .modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  .modal-container > h1 {
    color: #000;
    text-align: center;
    margin: 48px;
  }
  .modal-content > h2 {
    color: #EC0000;
    text-align: left;
    margin: 0px 0px 48px 0;
    font-weight: bolder;
  }
  .modal-sections {
    display: flex;
    background-color: #F2F2F2;
    align-items: center;
  }
  .modal-sections > .modal-text > h2 {
    color: #EC0000;
    text-align: center;
    font-weight: bolder;
  }
  .modal-text-two {
    background-color: #F2F2F2;
    padding: 0px 96px 96px 96px;
    text-align: center;
  }
  .modal-text-two > h1 {
    color: #000;
  }
  .modal-text-two > h1 > span {
    color: #EC0000;
    font-weight: bolder;
  }
  .modal-text-two > h1 > a {
    color: #EC0000;
  }
  .btn-modal-close {
    background-color: #EC0000;
    border: none;
    margin-left: 95%;
  }
  .btn-modal-close:hover {
    background-color: white;
    color: #EC0000;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @media (max-width: 768px) {
  .modal-sections {
    flex-direction: column;
  }
  .modal-content {
    padding: 8px;
  }
  .modal-content > .mb-3.row {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .modal-btn {
    width: 100%;
  }
  .modal-text-two {
    padding: 8px;
  }
  .modal-content > h2 {
    text-align: center;
  } 
  .modal-container {
    width: 90%;
    padding: 48px;
    max-height: 85vh; 
  }
}

  