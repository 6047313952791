.welcome-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 4%;
  row-gap: 20px;
}

.welcome-text > p {
  font-size: 20px;
  color: #444444;
}
.welcome-section {
  display: flex;
  align-items: center;
  margin: 20px;
  gap: 96px;
}
.welcome-section > .welcome-text > .action-primary {
  margin-left: 0px;
}
.welcome-section .images {
  display: flex;
  gap: 12px;
  align-items: center;
}
.product-list {
  padding: 10px;
  text-align: center;
}
.product-list > h1 {
  margin: 30px 0px 10px;
}
.product-list > p {
  font-size: 20px;
  margin-bottom: 48px;
  color: #767676;
}
.product-list .products {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.product-list .product-item {
  border: 1px solid #ddd;
  padding: 15px;
  flex: 1 1 calc(25% - 20px);
  text-align: center;
}
.banner-say-yes.payments {
  background-color: #E8F2F6;
  padding: 48px;
  margin: 96px;
}
.banner-content > .action-primary {
  margin-left: 0;
}
.faq-left > .product-subheading {
  text-align: left;
}
.faq-left > p {
  font-size: 20px;
  color: #767676;
}
@media only screen and (max-width: 768px) {
  .welcome-section {
    flex-wrap: wrap;
    margin: 48px 12px;
  }
  .welcome-section .images {
    flex-direction: column;
  }
  .advantage-item  {
    flex-direction: column;
  }
  .banner-say-yes {
    display: none;
  }
  .advantage-item > img {
    max-width: 350px;
  }
  .welcome-section .images > img {
    max-width: 350px;
  }   
  

}