.faq {
    margin: 2px;
  }
  .faq > h1 {
    text-align: center;
    margin: 30px 0px;
  }
  .accordion-header {
    color: #333;
    font-size: 1.1rem;
    text-align: left;
    width: 100%;
    border: none;
    cursor: pointer;
  }  