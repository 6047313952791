@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.botlers-messaging-button-iframe {
  z-index: 9999 !important;
  width: 190px !important;
  height: 120px !important;
  top: -20px !important;
  bottom: auto !important;
  left: 76% !important;
  right: auto !important;
  transform: scale(0.8) !important;
}


@media (max-width: 479px) {
  .botlers-messaging-button-iframe {
    left: 50% !important;
    transform: scale(0.7) !important;
    }
}