.blog-section-article {
    margin: 96px;
    display: grid;
    justify-items: center;
    text-align: center;
    font-size: 16px;
}
.blog-section-article > h2 {
    margin-bottom: 48px;
}
.section-image-left {
    display: grid;
    grid-template-columns: 40% 60%;
    justify-items: center;
    align-items: start;
}
.section-image-left > h2 {
    grid-column-start: 1;
    grid-column-end: 3;    
}
.section-image-left > p {
    text-align: center;
}
.section-image-left > img {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 6;
    max-height: 400px;
}
.section-image-middle-two {
    display: grid;
}
.section-image-middle-two > img {
    grid-row-start: 3;
    margin: 48px auto;
}
@media only screen and (max-width: 768px) {
    .blog-section-article > img {
        max-width: 100%;
    }
    .blog-section-article {
        margin: 16px;
    }
    .section-image-left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}