.banner-more-information {
    text-align: center;
    margin: 96px 0px;
    background-color: #F5F9FB;
    padding: 96px 0px;
}
@media only screen and (max-width: 768px) {
    .banner-more-information {
        margin: 96px 16px;
        padding: 32px;
    }
}
