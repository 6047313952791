.products-section {
    text-align: center;
    margin: 40px 40px;
  }
  .products-title {
    font-size: 2rem;
    font-weight: bold;
    margin: 96px 0;
  }
  .products-container {
    display: grid;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    grid-template-columns: 50% 50%;
  }
  .principal-product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .principal-product > .product-image > img {
    height: auto;
  }
  .product-card.secondary-product {
    display: grid;
    align-items: start;
  }
  .secondary-product > .product-actions {
    grid-row-start: 2;
    justify-content: flex-start;
  }

  .secondary-product > .product-content > .product-subheading {
    text-align: left;
  }
  .secondary-product > .product-content > .product-title {
    text-align: left;
  }
  .product-title {
    font-size: 1.5rem;
    color: #000;
    margin-top: 24px;
    text-align: center;
  }
  .product-title span {
    color: red; 
  }
  .product-bold {
    margin-top: 10px;
    font-size: 16px;
    color: black;
    font-weight: bold;
    padding: 0px 10px;
    text-align: center;
  }
  .product-regular {
    padding-left: 3px;
    margin: 10px 0;
    color: #767676;
    font-weight: lighter;
  }
  .product-content {
    max-width: 50%;
  }
  .product-actions {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 48px;
  }
  .product-image img {
    object-fit: contain;
    height: 300px;
    margin-top: 28px;
  }
  .product-subheading {
    text-transform: uppercase;
    color: #EC0000;
    font-size: 12px;
    font-weight: bolder;
    text-align: center;
  }
  .principal-product {
    display: flex;
    flex-direction: column;
  }  
  .secondary-product > .product-content > div > .product-bold {
    text-align: left;
    padding: 0;
    height: 68px;
  }
  .isostwo > .products-container {
    display: flex;
    gap: 48px;
  }
  .isostwo > .products-container > .product-card {
    width: 30%;
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    align-items: center;
    justify-content: space-between;
  }
  .isostwo > .products-container > .product-card > .product-image > img {
    height: auto;
  }
  @media only screen and (max-width: 768px) {
    .products-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .product-content {
      max-width: 90%;
    }
    .product-bold {
      padding: 0px;
    }
    .product-image img {
      max-width: 100%;
      margin-left: 0px;
    } 
    .product-actions {
      flex-direction: column;
      align-items: center;
    }
    .isostwo > .products-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .isostwo > .products-container > .product-card {
      width: 80%;
    }
    .values > .specifications-grid > .specification-card {
      width: 80% !important;
  }
  }