 .our-plugins {
    text-align: center;
 }
 .our-plugins-grid {
    display: flex;
    gap: 24px;
    justify-content: space-around;
 }
 .our-plugin-card {
    padding: 16px;
    width: 20%;
    border: solid 2px #F5F9FB;
    border-radius: 8px;
    text-align: left;
 }
 .our-plugin-card > img {
    margin-bottom: 16px;
 }
 @media only screen and (max-width: 768px) {
   .our-plugins-grid {
      flex-direction: column;
   }
   .our-plugin-card {
      width: 90%;
   }
}