.carousel-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 60%;
  height: 300px;
  perspective: 1000px;
  margin: 0 auto;
}

.card-stack {
  position: relative;
  width: 100%;
  height: 450px;
}

.card-text {
  padding: 15px;
}

.highlight {
  font-weight: bold;
  color: #007bff;
}

.secondary-text {
  color: #666;
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: transparent; 
  color: #007bff; 
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: color 0.3s ease; 
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.button:hover {
  color: #0056b3;
}

.button:active {
  transform: translateY(-50%) scale(0.95); 
}

.button.left {
  left: -60px;
}

.button.right {
  right: -60px;
}

.button span {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-text > h3 {
  text-align: left;
  margin-bottom: 24px;
}

.card-text > h3 > span {
  color: #EC0000;
}


@media only screen and (max-width: 1300px) {
  .card-stack {
    position: relative;
    width: 100%;
    height: 300px;
  }
  .carousel-container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 85%;
    height: 300px;
    perspective: 1000px;
    margin: 0 auto;
  }
}


@media only screen and (max-width: 767px) {
  .card-container {
    flex-direction: column;
    padding: 24px;
  }
  .card-container > .card-image > img {
    width: 75% !important;
  }
  .payment-carousel {
    display: none;
  }
  .card-stack {
    position: relative;
    width: 100%;
    height: 200px;
  }
  .carousel-container {
    position: relative;
    width: 95%;
    height: 300px;
    perspective: 1000px;
    margin: 10px 0px 10px 0px auto;
  }
  .button.left {
    left: 120px;
    top: 280px;
  }
  
  .button.right {
    right: 120px;
    top: 280px;

  }

.card-text {
  padding: 1px;
}
  
}


@media only screen and (max-width: 414px) {
  .card-container {
    flex-direction: column;
    padding: 24px;
  }
  .card-container > .card-image > img {
    width: 75% !important;
  }
  .payment-carousel {
    display: none;
  }
  .card-stack {
    position: relative;
    width: 100%;
    height: 160px;
  }
  .carousel-container {
    position: relative;
    width: 95%;
    height: 300px;
    perspective: 1000px;
    margin: 50px 0px 50px 0px auto;
  }
  .button.left {
    left: 120px;
    top: 280px;
  }
  
  .button.right {
    right: 120px;
    top: 280px;

  }

.card-text {
  padding: 1px;
}
  
}
