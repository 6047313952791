.sectors-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px auto;
}
.sectors-container {
  display: flex;
  width: 100%;
  gap: 2rem;
}
.sectors {
    background-color: #F5F9FB;
    padding: 48px;
  }
  .section-title {
    font-size: 2rem;
  }  
  .accordion-container {
    display: flex;
    flex-direction: column;
  }  
  .accordion-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
  }  
  .accordion-header {
    background-color: #f5f5f5;
    color: #333;
    font-size: 1.2rem;
    text-align: left;
    width: 100%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }  
  .accordion-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #fff;
  }  
  .content-text {
    text-align: left;
    flex: 1;
    margin-right: 20px;
  }  
  .content-text p {
    margin: 0 0 10px;
  }  
  .content-text small {
    display: block;
    color: #777;
    font-size: 0.9rem;
  }
  .content-image img {
    max-width: 150px;
    height: auto;
  }
  .image-container {
    flex: 1;     
    margin: auto;
    text-align: center;
  }
  
  .sector-image {
    width: 60%;
    height: auto;
    border-radius: 8px;
    object-fit: cover; 
  }
  
  /* Columna derecha: Texto */
  .accordion-container {
    flex: 1; /* Ocupa la mitad derecha */
  }
  
  .section-title {
    font-size: 2.8rem;
    color: #333;
    text-align: left;
  }
  
  .accordion-header {
    background: none;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0;
    text-align: left;
    cursor: pointer;
    color: #333;
    padding: 0;
  }
  .accordion-description {
    margin-top: 1rem;
    font-size: 1rem;
    color: #555;
  }
  .accordion-description > .action-secondary {
    border: none;
    background-color: transparent;
    font-weight: bolder;
    float: inline-end;
  }
  .accordion-description > .action-secondary:hover {
    border: none;
    background-color: transparent;
    color: #EC0000;
    text-decoration: underline;
  }
  .accordion-description > a {
    float: right;
    margin: 36px;
  }
  .accordion-description > a::after {
    content: '';
    background-image: url(../../public/media/images/home/arrow.png);
    width: 20px;
    height: 20px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat; 
  }
@media only screen and (max-width: 768px) {
  .section-title {
    text-align: center;
  }
  .sectors-container {
    flex-direction: column;
    text-align: center;
  }
}
  
  