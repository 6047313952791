section.banner-calculator {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    margin: 96px;
}
.calculator-text > h1 {
    margin-bottom: 24px;
}
.calculator-text > p {
    margin-bottom: 48px;
    border-bottom: solid 1px;
    padding-bottom: 16px;
}
.calculator-actions {
    color: #EC0000;
    font-weight: bold;
}
.calculator-text-two {
    grid-column-start: 1;
    grid-column-end: 3;
}
.banner-calculator-three {
    text-align: center;
}
.banner-calculator-three > h3 {
    color: #EC0000;
    font-size: 1rem;
    margin: 48px 0;
}

@media only screen and (max-width: 768px) {
    section.banner-calculator {
        margin: 16px;
    }
}