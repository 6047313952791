.b-m-button img.b-m-button-icon {
    width: 180%;
    height: 180%;
  }
  
  /* Contenedor de todas las burbujas */
  .chat-bubble-container {
    position: fixed; /* Para que quede cercano al botón */
    bottom: 212px; /* un poco más arriba del botón */
    right: 34px; /* ajusta la distancia horizontal */
    max-width: 280px; /* ajusta al tamaño deseado */
    font-family: sans-serif;
    z-index: 9999; /* para que aparezca encima de otros elementos */
  }
  
  .chat-bubble {
    position: relative;
    background-color: #f5f9fb; /* Fondo blanco para visibilidad */
    color: black; /* Texto oscuro */
    padding: 6px 16px; /* Espaciado interno uniforme */
    margin-bottom: 4px; /* Separación entre burbujas */
    width: 300px;
    border-radius: 0px 20px 20px 0px; /* Solo redondea las esquinas superiores e inferiores derechas */
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.15); /* Sombra para profundidad */
    font-size: 14px; /* Tamaño de fuente */
    line-height: 1; /* Espaciado adecuado entre líneas */
    display: inline-block; /* Asegura que el contenido se ajuste al texto */
  }
  
  /* Asegurar que los elementos <p> tengan un margen adecuado */
  .chat-bubble p {
    margin: 4px 0; /* Margen uniforme entre párrafos */
    padding: 0; /* Sin relleno adicional */
  }
  
  /* Estilo para el texto resaltado */
  .chat-bubble span {
    color: #0000ff; /* Azul para resaltar */
    font-weight: bold;
  }
  
  .chat-bubble-secondary {
    position: relative;
    background-color: #f5f9fb; /* Fondo blanco para visibilidad */
    color: #333; /* Texto oscuro */
    padding: 12px 16px;
    margin-top: 30px;
    border-radius: 8px;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.15); /* Sombra para profundidad */
    font-size: 14px;
    line-height: 1.4;
    display: inline-block; /* Asegura que el contenido se ajuste al texto */

  }
  
  /* La “punta” del globo en la primera burbuja */
  .chat-bubble::after {
    content: "";
    position: absolute;
    bottom: -8px; /* la punta sobresaldrá debajo */
    right: 20px; /* dónde quieres que aparezca la echita */
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #fff; /* color de la burbuja */
  }
  
  /* La punta del globo en la segunda burbuja */
  .chat-bubble-secondary::after {
    content: "";
    position: absolute;
    bottom: -8px;
    right: 20px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #fff;
  }
  
  /* Resaltar el texto “¡aquí!” */
  .chat-bubble span {
    color: #EC0000; 
    font-weight: bold;
  }
