.blog-banner-right {
    display: flex;
    margin: 48px 96px;
    background-color: #EBEEF3;
    padding: 48px;
    text-align: center;
    font-size: 16px;
}
@media only screen and (max-width: 768px) {
    .blog-banner-right {
        margin: 16px;
        flex-direction: column;
    }  
    .banner-image > img {
        max-width: 90%;
    }
}
