.navbar-toggler {
    padding: 0px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  
  .mobile-toggle {
    color: red !important;
  }
  
  .mobile-toggle .navbar-toggler-icon {
    filter: invert(21%) sepia(99%) saturate(7481%) hue-rotate(358deg) brightness(94%) contrast(119%);
  }
  
  .navbar-toggler:focus, 
  .navbar-toggler:active {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
  