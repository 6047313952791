.sectors-product {
    margin: 56px;
    place-items: center;
  }
  .sectors-product > p {
    font-size: 20px;
    color: #767676;
  }
  .sectors-product > .text {
    margin-bottom: 48px;
  }
  .sector-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: center;
  }
  .sector-card {
    background-color: #FBFBFB;
    max-width: 30%;
    padding-bottom: 16px;
  }
  .sector-card > img {
    margin: auto;
    max-width: 100%;
  }
  .sector-card > h3 {
    margin: 28px 16px;
  }
  .sector-card > p {
    margin: 28px 16px;
  }
  .sector-card > .action-terciary {
    margin: 0px 16px;
  }
  .action-terciary > a::after {
    content: '';
    background-image: url(../../public/media/images/home/arrow.png);
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-top: 2px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat; 
  }
  @media only screen and (max-width: 768px) {
  .sector-cards {
    flex-direction: column;
  }
  .sector-card {
    max-width: 90%;
    margin: auto;
  }
  .sector-card > img {
    width: 95%;
  }
  .sectors-product {
    margin: 16px;
  }
  .sectors-product > p {
    text-align: center;
  }
}