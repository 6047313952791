.banner-about {
    display: flex;
    padding: 96px 0px 96px 96px;
    gap: 96px;
    background-color: #DEEDF2;
    align-items: center;
}
.banner-text {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.banner-img {
    text-align: right;
}
.banner-img > img {
    max-width: 70%;
}
.options-list {
    padding-left: 0;
}
.options-list > li {
    list-style: none;
    padding-left: 30px; 
    margin: 8px 0;
    position: relative; 
}

.options-list > li::before {
    background-image: url(../../public/media/images/about/check-mark.png);
    content: '';
    position: absolute;
    width: 20px; 
    height: 20px; 
    background-size: contain; 
    background-repeat: no-repeat;
    left: 0; 
    top: 50%; 
    transform: translateY(-50%);
}
    
@media (max-width: 768px) {
    .sobregetnet-page > .banner-about {
        flex-direction: column;
        padding: 16px;
        gap: 32px;
        text-align: center;
    }
    .banner-about > .banner-img > img {
        max-width: 100%;
        margin: auto;
    }
    .options-list > li::before {
        background: none;
    }
    .sobregetnet-page > .sectors-product > h1 {
        text-align: center;
    }
}