.welcome-sections {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 96px;
    gap: 96px;
}
.welcome-sections > .welcome-img > img {
    max-width: 400px;
}
.banner-section {
    margin: 96px;
    text-align: center;
}
.banner-section > h1 {
    margin: 48px;
}
.banner-section > img {
    max-width: 80%;
}
.hoteles > .welcome-sections > .welcome-img > img {
    max-width: 700px;
}

@media only screen and (max-width: 768px) {
    .welcome-sections {
        grid-template-columns: 100%;
        margin: 16px;
        gap: 0;
    }
    .welcome-text {
        margin: 0 8%;
        text-align: center;
    }
    .welcome-text > p {
        margin: auto;
    }
    .welcome-sections > .welcome-img > img {
        max-width: 350px;
    }
    .banner-section {
        margin: 96px 16px;  
    }
}