.modalities {
    text-align: center;
    margin: 96px;
}
.modalities > p {
    font-size: 20px;
    color: #444444;
}
.modalities-grid {
    display: flex;
    gap: 96px;
    margin: 48px 0px;
    color: #444444;
}
.modalities-card {
    max-width: 45%;
    padding: 4% 2%;
    border: solid 2px #CEDEE7;
    border-radius: 8px;
    text-align: left;
}
.modalities-card > p {
    font-size: 20px;
    margin: 24px 0;
}
@media only screen and (max-width: 768px) {
    .modalities {
        margin: 16px;
    }
    .modalities-grid {
        flex-wrap: wrap;
        gap: 24px;
    }
    .modalities-card {
        max-width: 95%;
    }
}