.blog-recent-art {
    text-align: center;
}
.recent-articles {
    display: flex;
    gap: 48px;
    justify-content: center;
    margin-top: 48px;
}
.recent-art {
    max-width: 24%;
    text-align: left;
}
.recent-art > img {
    max-width: 300px;
    height: 180px;
}
.recent-art > p {
    color:#262626;
    margin: 24px 0px;
}
.recent-art > a {
    color: black;
    text-decoration: none;
}
@media only screen and (max-width: 768px) {
    .recent-articles {
        flex-direction: column;
        align-items: center;
    }
    .recent-art {
        max-width: 90%;
        text-align: center;
    }
}