.blog-welcome {
    text-align: center;
    display: flex;
    margin: 96px;
    flex-direction: column;
    gap: 48px;
}
@media only screen and (max-width: 768px) {
    .blog-welcome {
        margin: 16px;
    } 
}